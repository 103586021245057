


















































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from '@/api'

@Component({
    name: "couponList",
    components: {}
})

export default class extends Vue {
    // 表格数据
    tableData: any = [];
    // 加载状态
    loading = false;
    // 总条数
    total = 0
    // 搜索条件
    search: any = {
      keyword:"",
      couponNumber:"",
      zhikeId:"",
      brokerId:"",
      belong:"",
      channel:"",
      status:"",
      receiveType:"",
      receiveTime:[],
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 10, //每页数量
        },
    };
    config = {
        receiveType: {
            0: '领用',
            1: 'VIP权益领取',
            2: '系统发放'
        },
        status: {
            0: '未使用',
            1: '已使用',
            2: '已锁定',
            3: '已召回',
        },
        belong: {
            'zhike': '直客',
            'borker': '经纪人'
        }
    }

    channelData = []
    getChannel() {
        api
            .post('A23006', {})
            .then(res => {
                this.channelData = res.list
            })
    }

    protected async toDelete(item: any) {
        const h = this.$createElement;
        this.$msgbox({
            title: "操作提示",
            message: h("div", {}, [
                h(
                    "p",
                    {},
                    "召回优惠券，将同步从经纪人账号中删除该优惠券，是否召回？"
                ),
                h("p", {style: "font-size: 12px"}, "已领取优惠券经办人也将同步删除"),
            ]),
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
                if (action == 'confirm') {
                    api
                        .post('J27420', {id: item.id})
                        .then(res => {
                            this.$message.success('操作成功')
                            this.searchTable()
                            done()
                        })

                } else {
                    done()
                }
            },
        });
    }

   searchTable() {
    api.post('J27340', this.search)
        .then(res => {
          this.tableData = res.list
          this.total = res.total
        })
  }

  activated() {
        this.search.pagination = {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        }
        this.search.couponNumber = this.$route.query.couponNumber as string
        this.getChannel()
    this.searchTable()
  }



}
